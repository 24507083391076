import Logo from "../components/common/Logo";
import SearchBar from "../components/common/search/searchBar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Product from "../components/products/products";
import { getItemDetail } from "../services/getQuery";
import styles from "./allProductsPage.module.css";
import MainContainer from "../layout/mainContainer";
import ProductDetailSidebar from "../components/productDetail/sidebar";
import SearchSuggestion from "../components/common/search/searchSuggestion";
import { UserContext } from "../App";
import MyLottieAnimation from "../components/lottie-animation";
import AnimatedLine from "../components/productDetail/animatedLine";
import TagManager from "react-gtm-module";
import { useSearchParams } from "react-router-dom";

const ItemDetail = () => {
  const { inputValue, setInputValue, searched, setSearched } =
    useContext(UserContext);
  const [productDetail, setProductDetail] = useState({});
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getItemDetail(id)
      .then((data) => {
        if (data !== "prevQ") {
          setProductDetail(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        //
      })
      .finally(() => {
        // setLoading(false);
      });
  }, [id]);
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-PC4DM3M9" });
  }, []);

  return (
    <>
      <div className={styles.logo_container + " sticky_top"}>
        <Logo />
      </div>
      {loading ? (
        <>
          <AnimatedLine />
          <div
            style={{
              margin: "0 auto",
              marginTop: "calc((100vh - 20rem) / 2)",
              overflow: "hidden",
            }}
          >
            <MyLottieAnimation />
          </div>
        </>
      ) : (
        <MainContainer>
          <div className={styles.searchbar_container}>
            <SearchBar />
          </div>
          <div
            style={{ display: "flex", flexDirection: "row", gap: "3.75rem" }}
          >
            <div className={styles.product_detail_container}>
              <Product productDetail={productDetail} />;
            </div>
            <ProductDetailSidebar />
          </div>{" "}
          <div className={styles.bottomSidebar}>
            <SearchSuggestion detailBottom={true} />
            <div
              className={styles.btn}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSearched(false);
                navigate("/products/explore-trending-gifts" + location?.search);
              }}
            >
              <div className={styles.content}>
                <div className={styles.text}>Explore</div>{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.7385 16.08C15.628 16.183 15.5393 16.3072 15.4778 16.4452C15.4163 16.5832 15.3833 16.7321 15.3806 16.8832C15.3779 17.0342 15.4057 17.1843 15.4623 17.3244C15.5189 17.4644 15.6031 17.5917 15.7099 17.6985C15.8168 17.8054 15.944 17.8896 16.0841 17.9462C16.2242 18.0027 16.3742 18.0305 16.5253 18.0279C16.6763 18.0252 16.8253 17.9921 16.9633 17.9306C17.1013 17.8692 17.2255 17.7805 17.3285 17.67L22.205 12.795L23 12L22.205 11.205L17.33 6.32997C17.1179 6.12494 16.8338 6.01141 16.5388 6.01383C16.2438 6.01626 15.9616 6.13444 15.7529 6.34292C15.5443 6.55141 15.4258 6.83352 15.4231 7.12849C15.4204 7.42346 15.5337 7.70769 15.7385 7.91997L18.6935 10.875L3.125 10.875C2.82663 10.875 2.54048 10.9935 2.32951 11.2045C2.11853 11.4155 2 11.7016 2 12C2 12.2983 2.11853 12.5845 2.32951 12.7955C2.54048 13.0064 2.82663 13.125 3.125 13.125L18.6935 13.125L15.7385 16.08Z"
                    fill="url(#paint0_linear_439_1172)"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_439_1172"
                      x1="34.4843"
                      y1="7.28921"
                      x2="34.2984"
                      y2="20.5849"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#A04BFF" />
                      <stop offset="0.453125" stop-color="#FF4BA1" />
                      <stop offset="1" stop-color="#FFF625" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
          <div style={{ height: "2.5rem" }}></div>
        </MainContainer>
      )}
    </>
  );
};

export default ItemDetail;
