import "./page.css";
import Logo from "../components/common/Logo";
import ExploreBtn from "../components/common/exploreBtn";
import SearchHeader from "../components/common/search/searchHeader";
import SearchBar from "../components/common/search/searchBar";
import MainContainer from "../layout/mainContainer";
import LinearBG from "../components/common/linearBG";
import {
  AmazonIcon,
  FedexIcon,
  GoogleIcon,
  MicrosoftIcon,
  OlaIcon,
  OyoIcon,
  mobileAmazonIcon,
  mobileFedexIcon,
  mobileGoogleIcon,
  mobileMicrosoftIcon,
  mobileOyoIcon,
} from "../components/brand/brandSVG";
import InfiniteLooper from "../components/brand/InfiniteLooper";
import { useContext, useEffect } from "react";
import { UserContext } from "../App";
import { useSearchParams } from "react-router-dom";

const BrandsPage = () => {
  const largeBrand = [
    GoogleIcon,
    MicrosoftIcon,
    OyoIcon,
    FedexIcon,
    AmazonIcon,
    OlaIcon,
  ];
  const smallBrands = [
    mobileAmazonIcon,
    mobileFedexIcon,
    mobileOyoIcon,
    mobileMicrosoftIcon,
    mobileGoogleIcon,
  ];

  return (
    <div>
      <div>
        <MainContainer>
          <Logo />
        </MainContainer>

        <SearchHeader />
        <MainContainer>
          <SearchBar />
        </MainContainer>

        <MainContainer>
          <div className="mt-5 my-sm-5">
            <ExploreBtn />
          </div>
        </MainContainer>
      </div>
      {/*  */}
      <div className="largeScreenBrands">
        <InfiniteLooper speed="8" direction="right">
          {largeBrand.map((el, index) => (
            <div className="largeBrandContainer">{el}</div>
          ))}
        </InfiniteLooper>
      </div>
      {/*  */}
      <div className="smallScreenBrands">
        <InfiniteLooper speed="8" direction="right">
          {smallBrands.map((el, index) => (
            <div className="smallBrandContainer">{el}</div>
          ))}
        </InfiniteLooper>
        <InfiniteLooper speed="8" direction="left">
          {smallBrands.map((el, index) => (
            <div className="smallBrandContainer" style={{ marginTop: "2rem" }}>
              {el}
            </div>
          ))}
        </InfiniteLooper>
      </div>

      <LinearBG />
    </div>
  );
};

export default BrandsPage;
