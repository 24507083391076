import React from "react";
import ratings from "./ratings.module.css";

export const LargeStar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="25"
      viewBox="0 0 27 25"
      fill="none"
    >
      <g clipPath="url(#clip0_238_325)">
        <path
          d="M12.1547 1.33436L9.09377 7.54061L2.24533 8.53905C1.01721 8.71717 0.525022 10.2312 1.41565 11.0984L6.37033 15.9265L5.19846 22.7469C4.98752 23.9797 6.28596 24.9031 7.37346 24.3265L13.5 21.1062L19.6266 24.3265C20.7141 24.8984 22.0125 23.9797 21.8016 22.7469L20.6297 15.9265L25.5844 11.0984C26.475 10.2312 25.9828 8.71717 24.7547 8.53905L17.9063 7.54061L14.8453 1.33436C14.2969 0.228109 12.7078 0.214046 12.1547 1.33436Z"
          fill="#FED030"
        />
      </g>
      <defs>
        <clipPath id="clip0_238_325">
          <rect
            width="27"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SmallStar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
    >
      <g clipPath="url(#clip0_285_341)">
        <path
          d="M8.10314 1.26114L6.06251 5.39864L1.49689 6.06427C0.67814 6.18302 0.350015 7.19239 0.943765 7.77052L4.24689 10.9893L3.46564 15.5361C3.32501 16.358 4.19064 16.9736 4.91564 16.5893L9.00002 14.4424L13.0844 16.5893C13.8094 16.9705 14.675 16.358 14.5344 15.5361L13.7531 10.9893L17.0563 7.77052C17.65 7.19239 17.3219 6.18302 16.5031 6.06427L11.9375 5.39864L9.89689 1.26114C9.53127 0.523644 8.47189 0.514269 8.10314 1.26114Z"
          fill="#FED030"
        />
      </g>
      <defs>
        <clipPath id="clip0_285_341">
          <rect
            width="18"
            height="16"
            fill="white"
            transform="translate(0 0.704895)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
// const Ratings = () => {
//   return (
//     <>
//       <div className=" d_chatty_none d_chatty_sm_flex">
//         <LargeStar />
//         <LargeStar />
//         <LargeStar />
//         <LargeStar />
//         <LargeStar />
//       </div>
//       <div className=" d_chatty_flex d_chatty_sm_none">
//         <SmallStar />
//         <SmallStar />
//         <SmallStar />
//         <SmallStar />
//         <SmallStar />
//       </div>
//     </>
//   );
// };
const Ratings = ({ star }) => {
  const numStars = parseInt(star);

  // Create an array to hold the JSX elements for stars
  const stars = Array.from({ length: numStars }, (v, i) => (
    <LargeStar key={`largeStar-${i}`} />
  ));

  const smallStars = Array.from({ length: numStars }, (v, i) => (
    <SmallStar key={`smallStar-${i}`} />
  ));

  return (
    <>
      <div className="d_chatty_none d_chatty_sm_flex">{stars}</div>
      <div className="d_chatty_flex d_chatty_sm_none">{smallStars}</div>
    </>
  );
};

export default Ratings;
