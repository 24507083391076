import React from "react";
import flame from "../../assets/fire.png";
import Ratings from "../rating/ratings";
import styles from "./products.module.css";

const Product = ({ productDetail }) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.order_container}>
          <div className={styles.title_rating_container}>
            <div className={styles.title}>
              {" "}
              <a
                href={productDetail?.affiliate_url}
                rel="noreferrer"
                target="_blank"
                style={{
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                  color: "white",
                  textDecoration: "none",
                  width: "100%",
                }}
              >
                <span> {productDetail.name} </span>{" "}
              </a>
              - {productDetail.headline}
            </div>
            <div className={styles.rating_review_container}>
              <div className={styles.rating_text}>Rating </div>
              <Ratings star={productDetail.reviews} />
              <div className={styles.reviews_text}>
                ({productDetail.number_of_reviews} reviews)
              </div>
            </div>
          </div>
          {productDetail.imageType === "img" ? (
            <img
              src={productDetail.image}
              alt=""
              className={styles.detail_img}
            />
          ) : productDetail.imageType === "video" ? (
            <video
              src={productDetail.image}
              className={styles.detail_img}
              // loop
              // autoPlay
              // muted
              // playsinline
              loop
              autoPlay
              muted
              playsInline
              controls={false}
              controlsList="nodownload"
            />
          ) : (
            " "
          )}
        </div>
        <p className={styles.para_container}>
          {productDetail.copy?.replaceAll(".  ", ". ") || productDetail.copy}
        </p>

        <a
          href={productDetail?.affiliate_url}
          rel="noreferrer"
          target="_blank"
          style={{
            // whiteSpace: "nowrap",
            cursor: "pointer",
            color: "white",
            textDecoration: "none",
            width: "100%",
          }}
        >
          <div className={styles.learn_more_btn} style={{ cursor: "pointer" }}>
            <div className={styles.text}>
              Learn more about {productDetail.name}
            </div>{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M10.334 3.33337H7.33398L10.6673 8.00004L7.33398 12.6667H10.334L13.6673 8.00004L10.334 3.33337Z"
                fill="white"
              />
              <path
                d="M5.66602 3.33337H2.66602L5.99935 8.00004L2.66602 12.6667H5.66602L8.99935 8.00004L5.66602 3.33337Z"
                fill="white"
              />
            </svg>
          </div>
        </a>
        <div style={{ height: "4rem" }}></div>
      </div>
    </>
  );
};

export default Product;
