import Logo from "../components/common/Logo";
import ExploreBtn from "../components/common/exploreBtn";
import "./page.css";
import SearchHeader from "../components/common/search/searchHeader";
import SearchBar from "../components/common/search/searchBar";
import CardContainer from "../components/Home/card/cardContainer";
import MainContainer from "../layout/mainContainer";
import LinearBG from "../components/common/linearBG";
import SearchSuggestion from "../components/common/search/searchSuggestion";
import Backtotopbtn from "../components/common/backtotopbtn";
import styles from "./homePage.module.css";
import SearchContainer from "../components/common/search/animatedPlaceholder";
import { useLocation, useSearchParams } from "react-router-dom";
import { useEffect, useContext } from "react";
import { UserContext } from "../App";

const HomePage = () => {

  return (
    <>
      <div className="sticky_top">
        <Logo />
      </div>

      <div style={{ height: "4.12rem" }}></div>

      <div className="flex-center">
        <SearchHeader />
      </div>
      <div style={{ height: "2.5rem" }}></div>
      <div className="flex-center">
        <div className={styles.searchbar_container}>
          <SearchBar />
        </div>
      </div>

      <div style={{ height: "2.5rem" }}></div>
      <MainContainer>
        <div className="flex-center">
          {" "}
          <div style={{ width: "53.75rem" }} className="flex-center">
            <SearchSuggestion />
          </div>
        </div>
      </MainContainer>

      <div style={{ height: "6.25rem" }}></div>
      <MainContainer>
        <CardContainer />
      </MainContainer>
      <div style={{ height: "4.5rem" }}></div>
      <div className="sticky_bottom">
        {" "}
        <MainContainer>
          <div className="flex_h_end flex-xsm-center ">
            <Backtotopbtn />
          </div>
        </MainContainer>
      </div>

      <div style={{ height: "2.5rem" }}></div>
    </>
  );
};

export default HomePage;
