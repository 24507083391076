import { useEffect, useState } from "react";
import styles from "./backtotopbtn.module.css";

const Backtotopbtn = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Attach event listener when component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {" "}
      {isVisible && (
        <div className={styles.container} onClick={scrollToTop}>
          <div className={styles.btn_container}>
            <div className={styles.text}>Back to top</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <mask
                id="mask0_439_664"
                maskUnits="userSpaceOnUse"
                x="2"
                y="1"
                width="20"
                height="23"
              >
                <path
                  d="M20.4961 19.5H9.49609V22.5H20.4961V19.5Z"
                  fill="white"
                  stroke="white"
                  stroke-width="2"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.49685 19.5C6.23735 15.9385 4.36685 13.875 3.88585 13.308C3.16435 12.458 3.46735 11.498 5.27335 11.498C7.07935 11.498 8.12085 14.1405 9.49685 14.1405C9.50535 14.1425 9.50585 10.763 9.49835 4.002C9.49809 3.80493 9.53666 3.60975 9.61186 3.42759C9.68706 3.24544 9.79742 3.07989 9.93663 2.9404C10.0758 2.80091 10.2412 2.69022 10.4232 2.61466C10.6052 2.53909 10.8003 2.50013 10.9974 2.5H10.9989C11.1961 2.49993 11.3915 2.53874 11.5738 2.61419C11.7561 2.68964 11.9217 2.80027 12.0612 2.93975C12.2008 3.07923 12.3115 3.24483 12.387 3.42709C12.4625 3.60936 12.5014 3.80471 12.5014 4.002V8.007C16.4874 8.611 18.6549 8.9445 19.0029 9.007C19.5254 9.101 20.4969 9.6 20.4969 11.034V19.5H9.49685Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linejoin="round"
                />
              </mask>
              <g mask="url(#mask0_439_664)">
                <path d="M0 0.5H24V24.5H0V0.5Z" fill="white" />
              </g>
            </svg>
          </div>

          {/* Content for text */}
        </div>
      )}
    </>
  );
};

export default Backtotopbtn;
