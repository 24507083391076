import React, { useState, useEffect } from "react";
import styles from "./animatedPlaceholder.module.css"; // Assuming you have a CSS file

const AnimatedPlaceholder = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const texts = [
    "Gifts for my mom",
    "Unique holiday gifts",
    "Christmas gift ideas",
  ];

  return (
    <div className={styles.searchContainer}>
      {texts.map((text, index) => (
        <div
          key={index}
          className={
            styles.text +
            " " +
            ` ${index === currentIndex ? styles.visible : ""}`
          }
        >
          {text}
        </div>
      ))}
    </div>
  );
};

export default AnimatedPlaceholder;
