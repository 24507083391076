import React from "react";
import flame from "../../assets/fire.png";
import Ratings from "../rating/ratings";
import styles from "./products.module.css";

const ProductSkeleton = ({}) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.order_container}>
          <div className={styles.title_rating_container}>
            <div className={styles.title_skeleton}></div>
            <div className={styles.rating_review_container_skeleton}></div>
          </div>
        </div>
        <div className={styles.image_skeleton}></div>
        <p className={styles.para_container_skeleton}></p>
        {/* <div
          className={styles.learn_more_btn_skeleton}
          style={{ cursor: "pointer" }}
        ></div> */}
        <div style={{ height: "4rem" }}></div>
      </div>
    </>
  );
};

export default ProductSkeleton;
