import React from "react";

const LinearBG = () => {
  return (
    <div
      className="row w-100"
      style={{
        // position: "relative",
        zIndex: -1,
        position: "fixed",
        top: "60vh",
        height: "40vh",
        width: "100%",
        transform: "translateY(20%)",
      }}
    >
      {/* <div className="col bg-shape bg-teal opacity-50 bg-blur"></div>
  <div className="col bg-shape bg-primary opacity-50 bg-blur"></div> */}
      <div
        className="col bg-shape opacity-50 bg-blur"
        style={{ backgroundColor: "#E129FF" }}
      ></div>
      <div
        className="col bg-shape  opacity-50 bg-blur"
        style={{ backgroundColor: "#5C1EC1" }}
      ></div>
      <div
        className="col bg-shape  opacity-50 bg-blur"
        style={{ backgroundColor: "#2561D6" }}
      ></div>
      <div
        className="col bg-shape  opacity-50 bg-blur"
        style={{ backgroundColor: "#1CA039" }}
      ></div>
      <div
        className="col bg-shape  opacity-50 bg-blur"
        style={{ backgroundColor: "#94C42E" }}
      ></div>
      <div
        className="col bg-shape  opacity-50 bg-blur"
        style={{ backgroundColor: "#CEFF41" }}
      ></div>
      <div
        className="col bg-shape  opacity-50 bg-blur"
        style={{ backgroundColor: "#F28434" }}
      ></div>
      <div
        className="col bg-shape  opacity-50 bg-blur"
        style={{ backgroundColor: "#F0B135" }}
      ></div>
      <div
        className="col bg-shape  opacity-50 bg-blur"
        style={{ backgroundColor: "#FE3131" }}
      ></div>
    </div>
  );
};

export default LinearBG;
