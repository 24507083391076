import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/homePage";
import BrandsPage from "./pages/brandsPage";
import AllProductsPage from "./pages/allProductsPage";
import ItemDetail from "./pages/ItemDetailPage";
import { createContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const UserContext = createContext();
function App() {
  const [inputValue, setInputValue] = useState("");
  const [searched, setSearched] = useState(false);

  return (
    <>
      <UserContext.Provider
        value={{
          inputValue,
          setInputValue,
          searched,
          setSearched,
        }}
      >
        <Router>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="brands" element={<BrandsPage />} />
            <Route path="item/:id" element={<ItemDetail />} />
            <Route path="products/:name" element={<AllProductsPage />} />

            <Route
              path="*"
              element={
                <h1 className="text-center text-danger pt-5">Not Found</h1>
              }
            />
          </Routes>
        </Router>
      </UserContext.Provider>
    </>
  );
}

export default App;
