import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import style from "./exploreBtn.module.css";
const ExploreBtn = () => {
  const { inputValue } = useContext(UserContext);

  const navigate = useNavigate();
  return <></>;
};

export default ExploreBtn;
