import React, { useContext, useEffect, useState } from "react";
import styles from "./searchSuggestion.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import image8 from "../../../assets/image 8.png";
import image81 from "../../../assets/image 8 (1).png";
import image9 from "../../../assets/image 9.png";
import image92 from "../../../assets/image 9 (2).png";
import { UserContext } from "../../../App";

const SearchSuggestion = ({ detailBottom }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setSearched } = useContext(UserContext);
  const [isTablet, setIsTablet] = useState(false);
  let suggestions = [
    {
      img: image8,
      text: "Here Are Hottest Xmas Gifts of 2023",
      query: "xmas gifts",
    },
    {
      img: image81,
      text: "Here Are The Coolest Gadgets of 2023",
      query: "coolest gadgets",
    },
    {
      img: image9,
      text: "These Are The Coolest Birthday Gifts",
      query: "cool birthday gifts",
    },
    {
      img: image92,
      text: "The Coolest Gifts under $50",
      query: "coolest gifts",
    },
  ];

  useEffect(() => {
    const checkDeviceType = () => {
      const width = window.innerWidth;
      setIsTablet(width >= 768 && width < 1024); // Assuming tablet width range is 768px to 1024px
    };
    checkDeviceType();
    window.addEventListener("resize", checkDeviceType);
    return () => {
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);
  return (
    <>
      <div
        className={
          detailBottom && isTablet ? styles.detail_container : styles.container
        }
      >
        {suggestions.map((suggestion) => {
          return (
            <div
              className={
                detailBottom && isTablet
                  ? styles.detail_suggestion_container
                  : styles.suggestion_container
              }
              onClick={() => {
                setSearched(false);
                navigate("/products/" + suggestion.query + location?.search);
              }}
              style={{ cursor: "pointer" }}
            >
              {/* Content for suggestion_container */}{" "}
              <div
                className={
                  detailBottom && isTablet
                    ? styles.detail_suggestion_cont
                    : styles.suggestion_cont
                }
              >
                {/* Content for suggestion_cont */}
                <div
                  className={
                    detailBottom && isTablet ? styles.detail_img : styles.img
                  }
                >
                  {/* Content for img */}{" "}
                  <img
                    src={suggestion.img}
                    alt=""
                    style={{ borderRadius: ".5rem" }}
                  />{" "}
                </div>
                <div
                  className={
                    detailBottom && isTablet
                      ? styles.detail_suggestion_text
                      : styles.suggestion_text
                  }
                >
                  {suggestion.text}
                </div>
              </div>
              <div
                className={
                  detailBottom && isTablet
                    ? styles.detail_suggestion_right_arrow
                    : styles.suggestion_right_arrow
                }
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M8.625 5.25L15.375 12L8.625 18.75"
                    stroke="#D0D0D0"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default SearchSuggestion;
