import Load from "./load.json";
import Lottie from "lottie-react";
import style from "./lottie.module.css";
const MyLottieAnimation = () => {
  return (
    <div className="flex-center">
      <Lottie
        animationData={Load}
        background="#FFFFFF"
        speed={1}
        className={style.lottieLoader}
        loop
        controls
        autoplay
        direction={1}
        mode="normal"
      />
    </div>
  );
};

export default MyLottieAnimation;
