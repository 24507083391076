import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./searchBar.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../App";
import AnimatedPlaceholder from "./animatedPlaceholder";
import { useLocation } from "react-router-dom";

const SearchBar = () => {
  let { name } = useParams();
  const navigate = useNavigate();
  const [showSuggestions, setShowSuggestion] = useState(false);
  const { inputValue, setInputValue, searched, setSearched } =
    useContext(UserContext);
  const [typing, setTyping] = useState(true);
  const inputRef = useRef();
  const location = useLocation();

  const handleDivClick = () => {
    inputRef.current.focus();
  };
  const handleSearch = () => {
    setSearched(true);
    console.log("query", location.pathname);
    navigate("/products/" + inputValue + location?.search);
  };
  useEffect(() => {
    setTyping(false);
    setInputValue("");
    if (name?.length && searched) {
      setInputValue(name);
    }
  }, []);
  return (
    <>
      {/* Content for container */}{" "}
      <div className={styles.input_container}>
        {/* Content for input_container */}{" "}
        <input
          ref={inputRef}
          className={styles.input}
          onChange={(e) => {
            setInputValue(e.target.value);
            localStorage.setItem("chattyInput", e.target.value);
            setShowSuggestion(true);
          }}
          onKeyUp={(event) => {
            if (event.key === "Enter") {
              handleSearch();
            }
            setTyping(true);
          }}
          onFocus={(event) => {
            setTyping(true);
          }}
          value={inputValue}
        />
        <div className={styles.search_icon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_510_137)">
              <path
                d="M17.6124 15.516C19.0648 13.5341 19.7154 11.0768 19.4338 8.63575C19.1523 6.19474 17.9595 3.95004 16.094 2.35072C14.2286 0.75141 11.828 -0.084566 9.37265 0.0100437C6.91728 0.104653 4.58817 1.12287 2.8513 2.86099C1.11442 4.59911 0.0978694 6.92894 0.00501667 9.38438C-0.0878361 11.8398 0.749858 14.2398 2.35051 16.1041C3.95115 17.9684 6.19671 19.1596 8.63792 19.4394C11.0791 19.7192 13.536 19.0669 15.5169 17.613H15.5154C15.5604 17.673 15.6084 17.73 15.6624 17.7855L21.4374 23.5605C21.7187 23.842 22.1002 24.0002 22.4981 24.0003C22.896 24.0005 23.2777 23.8425 23.5591 23.5613C23.8406 23.28 23.9988 22.8985 23.9989 22.5006C23.9991 22.1027 23.8412 21.721 23.5599 21.4395L17.7849 15.6645C17.7313 15.6102 17.6736 15.5616 17.6124 15.516ZM17.9994 9.75003C17.9994 10.8334 17.786 11.9062 17.3714 12.9072C16.9568 13.9081 16.3491 14.8176 15.583 15.5837C14.8169 16.3497 13.9075 16.9574 12.9065 17.372C11.9056 17.7866 10.8328 18 9.74939 18C8.66599 18 7.59319 17.7866 6.59226 17.372C5.59132 16.9574 4.68185 16.3497 3.91576 15.5837C3.14968 14.8176 2.54199 13.9081 2.12739 12.9072C1.71279 11.9062 1.49939 10.8334 1.49939 9.75003C1.49939 7.56199 2.36859 5.46357 3.91576 3.9164C5.46294 2.36922 7.56136 1.50003 9.74939 1.50003C11.9374 1.50003 14.0358 2.36922 15.583 3.9164C17.1302 5.46357 17.9994 7.56199 17.9994 9.75003Z"
                fill="#868686"
              />
            </g>
            <defs>
              <clipPath id="clip0_510_137">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        {!inputValue.length ? (
          <div className={styles.animatedP} onClick={handleDivClick}>
            <AnimatedPlaceholder />
          </div>
        ) : (
          ""
        )}
        {!inputValue.length ? (
          <div className={styles.default_search_btn}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M12.8141 12.197L5.2821 13.452C5.19551 13.4664 5.11425 13.5034 5.04649 13.5593C4.97873 13.6151 4.92686 13.6878 4.8961 13.77L2.2991 20.728C2.0511 21.368 2.7201 21.978 3.3341 21.67L21.3341 12.67C21.4585 12.6076 21.5631 12.5119 21.6362 12.3935C21.7093 12.2751 21.7481 12.1387 21.7481 11.9995C21.7481 11.8603 21.7093 11.7239 21.6362 11.6055C21.5631 11.4871 21.4585 11.3913 21.3341 11.329L3.3341 2.32899C2.7201 2.02199 2.0511 2.63199 2.2991 3.27099L4.8971 10.229C4.92786 10.3112 4.97973 10.3839 5.04749 10.4397C5.11525 10.4955 5.19651 10.5325 5.2831 10.547L12.8151 11.802C12.8621 11.8095 12.9049 11.8335 12.9357 11.8697C12.9666 11.9059 12.9836 11.9519 12.9836 11.9995C12.9836 12.0471 12.9666 12.0931 12.9357 12.1293C12.9049 12.1655 12.8621 12.1895 12.8151 12.197H12.8141Z"
                fill="#D0D0D0"
              />
            </svg>
          </div>
        ) : (
          ""
        )}
        {inputValue.length && typing ? (
          <div
            className={styles.typing_search_btn}
            style={{ cursor: "pointer" }}
            onClick={() => handleSearch()}
          >
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="34" height="34" rx="4" fill="#4886FF" />
              <path
                d="M17.8161 17.1973L10.2841 18.4523C10.1975 18.4667 10.1162 18.5037 10.0484 18.5595C9.98068 18.6154 9.92881 18.688 9.89806 18.7703L7.30106 25.7283C7.05306 26.3683 7.72206 26.9783 8.33606 26.6703L26.3361 17.6703C26.4605 17.6079 26.5651 17.5122 26.6382 17.3938C26.7113 17.2753 26.75 17.1389 26.75 16.9998C26.75 16.8606 26.7113 16.7242 26.6382 16.6058C26.5651 16.4874 26.4605 16.3916 26.3361 16.3293L8.33606 7.32926C7.72206 7.02226 7.05306 7.63226 7.30106 8.27126L9.89906 15.2293C9.92981 15.3115 9.98168 15.3842 10.0494 15.44C10.1172 15.4958 10.1985 15.5328 10.2851 15.5473L17.8171 16.8023C17.864 16.8098 17.9068 16.8338 17.9377 16.87C17.9686 16.9062 17.9855 16.9522 17.9855 16.9998C17.9855 17.0473 17.9686 17.0934 17.9377 17.1296C17.9068 17.1658 17.864 17.1898 17.8171 17.1973H17.8161Z"
                fill="white"
              />
            </svg>
          </div>
        ) : (
          ""
        )}
        {inputValue.length && !typing ? (
          <div
            className={styles.rest_search_btn}
            style={{ cursor: "pointer" }}
            onClick={() => setInputValue("")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M20 20L4 4M20 4L4 20"
                stroke="#222222"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SearchBar;
