import { getFileExtension } from "../utils/getFileExtension";
// api config
const BASE_URL = "https://api.chattygifts.io/";
// const HOME_PAGE_PRODUCTS_ID = [40, 43, 45, 38]; // show these products at home page
const EXPLORE_TRENDING_GIFTS_API_URL =
  // BASE_URL + "categories/trending-gifts/products/ ";
  "https://api.chattygifts.io/list-products/2/";
const ALL_PRODUCTS_API_URL = BASE_URL + "search-products/?q=";
const PRODUCT_DETAILS_API_URL = BASE_URL + "get-product/";
const API_TOKEN = "Y2hhdHR5Z2lmdHM6YWRtaW4xMjNAIw==";
//
// get all product with details
let lastLocation = "";
export const getallProduct = async (name) => {
  console.log(
    "name: " + name,
    "lastQ: " + lastLocation,
    "location: " + window.location.href
  );
  // console.log(lastQ, name);
  if (lastLocation === window.location.href) {
    return "prevQ";
  } else {
    lastLocation = window.location.href;
    try {
      if (name) {
        let url = "";
        if (name === "explore-trending-gifts") {
          // it is called in homepage and when hits /explore-trending-gifts route ( navigated by explore all button )
          url = EXPLORE_TRENDING_GIFTS_API_URL;
        } else {
          url = ALL_PRODUCTS_API_URL + name;
        }
        const response = await fetch(url, {
          headers: {
            Authorization: "Basic " + API_TOKEN,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        let data = await response.json();
        if (data.products) {
          data = data.products;
        }

        console.log(data);
        data.forEach((item) => {
          // console.log(item);
          item.image =
            item?.image?.replace(
              "https://chatty-gifts-s3.s3.amazonaws.com/",
              "https://d136np6c4rfyqa.cloudfront.net/"
            ) || item.image;

          // console.log(item);

          const type = getFileExtension(item?.image);
          if (type === "img") {
            item.imageType = "img";
          } else if (type === "video") {
            item.imageType = "video";
          }
        });

        return data;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
};
// GET details of a product by its ID

export const getItemDetail = async (id) => {
  console.log(
    "name: " + id,
    "lastQ: " + lastLocation,
    "location: " + window.location.href
  );
  // console.log(lastQ, name);
  if (lastLocation === window.location.href) {
    return "prevQ";
  } else {
    lastLocation = window.location.href;
    try {
      if (id) {
        let url = PRODUCT_DETAILS_API_URL + id;
        const response = await fetch(url, {
          headers: {
            Authorization: "Basic " + API_TOKEN,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        // replace
        data.image =
          data?.image?.replace(
            "https://chatty-gifts-s3.s3.amazonaws.com/",
            "https://d136np6c4rfyqa.cloudfront.net/"
          ) || data.image;

        const type = getFileExtension(data?.image);
        if (type === "img") {
          data.imageType = "img";
        } else if (type === "video") {
          data.imageType = "video";
        }
        console.log(data);
        return data;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }
};
// get home page products

// export const fetchHomePageProducts = async () => {
//   try {
//     const promises = HOME_PAGE_PRODUCTS_ID.map(async (productId) => {
//       const url = PRODUCT_DETAILS_API_URL + productId;
//       return fetch(url, {
//         headers: {
//           Authorization: "Basic " + API_TOKEN,
//           "Content-Type": "application/json",
//         },
//       }).then((resp) => resp.json());
//     });

//     const productsData = await Promise.all(promises);
//     productsData.forEach((item) => {
//       item.image =
//         item?.image?.replace(
//           "https://chatty-gifts-s3.s3.amazonaws.com/",
//           "https://d136np6c4rfyqa.cloudfront.net/"
//         ) || item.image;
//     });
//     console.log(productsData);
//     return productsData;
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   }
// };
