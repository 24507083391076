import React from "react";
import styles from "./notFound.module.css"; // Replace with your actual CSS module path
import { useLocation, useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className={styles.not_found_container}>
      <svg
        width="298"
        height="237"
        viewBox="0 0 298 237"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.40625 15.293L19.8726 8.67285C23.699 6.46372 28.5917 7.77472 30.8008 11.6011L44.2178 34.84L32.7514 41.4601C28.9251 43.6692 24.0324 42.3582 21.8232 38.5319L8.40625 15.293Z"
          fill="#33474F"
        />
        <path
          d="M3.0625 24.729C1.54372 22.0983 2.44503 18.7346 5.07564 17.2158L8.40611 15.293L11.3797 20.4435L3.28613 25.1163L3.0625 24.729Z"
          fill="#EEEEEE"
        />
        <path
          d="M6.23047 23.4171L11.3809 20.4434L13.0802 23.3866L10.8728 24.661C9.24737 25.5994 7.16892 25.0425 6.23047 23.4171Z"
          fill="#E0E0E0"
        />
        <path
          d="M27.5977 17.9474L32.9091 14.8809C35.0338 13.6542 37.6913 13.8446 39.6194 15.3618L47.2138 21.3377L37.455 24.9937C34.7084 26.0227 31.6204 24.9151 30.1539 22.375L27.5977 17.9474Z"
          fill="#D9D9D9"
        />
        <circle
          cx="19.5877"
          cy="17.6669"
          r="3.39844"
          transform="rotate(-30 19.5877 17.6669)"
          fill="#FFFFFE"
        />
        <path
          d="M44.2188 34.84L46.4261 33.5656C46.4261 33.5656 47.9581 34.8114 47.7005 35.7729C47.4429 36.7344 45.4932 37.0473 45.4932 37.0473L44.2188 34.84Z"
          fill="#33474F"
        />
        <path
          d="M37.9961 44.8799L46.4961 53.3799"
          stroke="#EEEEEE"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M49.9961 48.8798L58.4961 57.3798"
          stroke="#EEEEEE"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M43.9961 58.8799L52.4961 67.3799"
          stroke="#EEEEEE"
          stroke-width="2"
          stroke-linecap="round"
        />
        <circle
          cx="186.996"
          cy="125.88"
          r="73"
          stroke="#EEEEEE"
          stroke-width="20"
        />
        <rect
          x="243.996"
          y="201.972"
          width="27"
          height="48.9354"
          rx="13.5"
          transform="rotate(-45 243.996 201.972)"
          fill="#4886FF"
        />
        <path
          d="M135.07 169.088L156.57 153.088L158.07 140.588L177.57 123.088L179.07 96.5877L189.07 85.0877L185.57 76.0877"
          stroke="#E0E0E0"
        />
        <path
          d="M157.293 152.701L135.293 168.701C160.96 186.368 213.436 191.504 230.293 158.201C246.825 125.541 206.626 82.0347 185.793 75.7014L189.793 85.2014L180.293 95.2014L177.793 123.201L158.293 140.201L157.293 152.701Z"
          fill="#F4F4F4"
        />
        <path
          d="M214.203 132.229L208.86 120.484"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M217.953 125.734L205.11 126.979"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M197.453 161.241L192.11 149.496"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M201.203 154.746L188.36 155.991"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
        />
        <ellipse
          cx="187.601"
          cy="131.305"
          rx="11"
          ry="6"
          transform="rotate(120 187.601 131.305)"
          fill="#334750"
        />
        <ellipse
          cx="206.044"
          cy="111.355"
          rx="7.5"
          ry="1"
          transform="rotate(120 206.044 111.355)"
          fill="#D9D9D9"
        />
        <ellipse
          cx="178.044"
          cy="159.852"
          rx="7.5"
          ry="1"
          transform="rotate(120 178.044 159.852)"
          fill="#D9D9D9"
        />
        <path
          d="M72.9961 136.88L77.9961 142.88L89.3137 140.154L112.996 151.88L126.399 130.978L135.996 135.38L151.617 125.88L152.996 126.899"
          stroke="#E0E0E0"
          stroke-linecap="round"
        />
        <path
          d="M115.102 185.38C74.7017 185.38 69.935 152.713 72.6017 136.38L78.1017 142.88L89.1017 140.38L113.102 151.88L126.602 131.38L136.102 135.38L151.602 126.38C151.935 126.38 152.902 126.48 154.102 126.88C154.102 129.38 165.602 185.38 115.102 185.38Z"
          fill="#F4F4F4"
        />
      </svg>
      <div className={styles.text}>
        Sorry, we couldn't find any search results.
      </div>
      <div className={styles.btn}>
        <div
          className={styles.text}
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate("/products/explore-trending-gifts" + location?.search)
          }
        >
          Explore exciting gifts
        </div>
      </div>
    </div>
  );
};

export default NotFound;
