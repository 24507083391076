import React, { useContext, useRef, useState } from "react";
import styles from "./cardStyle.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getallProduct } from "../../../services/getQuery";
import Card from "./Card";
import { UserContext } from "../../../App";

const a = [0, 1, 2, 3, 4, 5, 6, 7];
function getRandomIndexes(arr, count) {
  // Copy the array to avoid modifying the original
  const shuffled = arr.slice();

  // Shuffle the array
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }

  return shuffled.slice(0, count);
}

const CardContainer = () => {
  const { setSearched } = useContext(UserContext);
  const navigate = useNavigate();
  const [cards, setcards] = useState([]);
  const location = useLocation();

  useEffect(() => {
    getallProduct("explore-trending-gifts").then((productsData) => {
      if (productsData !== "prevQ") {
        const randomHighPriorityGifts = getRandomIndexes(productsData, 8);
        setcards(randomHighPriorityGifts);
      }
    });
  }, []);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.trending_container}>
          {" "}
          <div className={styles.trend}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.4986 5.25C9.29968 5.25 9.10892 5.17098 8.96827 5.03033C8.82761 4.88968 8.7486 4.69891 8.7486 4.5C8.7486 4.30109 8.82761 4.11032 8.96827 3.96967C9.10892 3.82902 9.29968 3.75 9.4986 3.75H13.2486C13.4475 3.75 13.6383 3.82902 13.7789 3.96967C13.9196 4.11032 13.9986 4.30109 13.9986 4.5V8.25C13.9986 8.44891 13.9196 8.63968 13.7789 8.78033C13.6383 8.92098 13.4475 9 13.2486 9C13.0497 9 12.8589 8.92098 12.7183 8.78033C12.5776 8.63968 12.4986 8.44891 12.4986 8.25V6.3105L9.27885 9.53025C9.1382 9.67085 8.94747 9.74984 8.7486 9.74984C8.54972 9.74984 8.35899 9.67085 8.21835 9.53025L6.4986 7.8105L3.27885 11.0302C3.13739 11.1669 2.94794 11.2425 2.7513 11.2408C2.55465 11.239 2.36654 11.1602 2.22748 11.0211C2.08843 10.8821 2.00955 10.6939 2.00784 10.4973C2.00613 10.3007 2.08173 10.1112 2.21835 9.96975L5.96835 6.21975C6.10899 6.07915 6.29972 6.00016 6.4986 6.00016C6.69747 6.00016 6.8882 6.07915 7.02885 6.21975L8.7486 7.9395L11.4381 5.25H9.4986Z"
                fill="white"
              />
            </svg>

            <div className={styles.text}>Trending gifts</div>
          </div>{" "}
          <div
            className={styles.explore}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSearched(false);
              navigate("/products/explore-trending-gifts" + location?.search);
            }}
          >
            Explore all
          </div>
        </div>{" "}
        {/*  */}
        {/*
         */}
        <div className={styles.cards_container}>
          {cards.length > 0
            ? cards.map((card, key) => <Card card={card} />)
            : a.map((item) => {
                return (
                  <div class={styles.skeleton_card}>
                    <div class={styles.skeleton_card_image}></div>
                    <div class={styles.skeleton_card_content}>
                      <div class={styles.description}></div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
    </>
  );
};

export default CardContainer;
