import styles from "./animatedLine.module.css";

const AnimatedLine = () => {
  return (
    <section className={styles.seperator_wrapper}>
      <div className={styles.seperator + " " + styles.gradient}></div>
    </section>
  );
};

export default AnimatedLine;
