import { Link, useLocation } from "react-router-dom";
import styles from "./card.module.css";

const Card = ({ card }) => {
  const location = useLocation();
  return (
    <Link
      to={`/item/${card.id}${location?.search}`}
      style={{ textDecoration: "none" }}
    >
      {" "}
      <div className={styles.c_container}>
        {card.imageType === "img" ? (
          <img src={card.image} alt="" className={styles.detail_img} />
        ) : card.imageType === "video" ? (
          <video
            src={card.image}
            className={styles.detail_img}
            // loop
            // autoPlay
            // muted
            loop
            autoPlay
            muted
            playsInline
            controls={false}
            controlsList="nodownload"
          />
        ) : (
          ""
        )}
        <div className={styles.text_wrap}>
          {/* <div className={styles.text}>{card.copy.split(/[?.!]/)[0]}</div> */}
          <div className={styles.text}>{card.headline}</div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
