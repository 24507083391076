export function getFileExtension(url) {
  const types = new Map([
    ["jpg", "img"], // ['file extension', 'file type']
    ["jpeg", "img"],
    ["png", "img"],
    ["webp", "img"],
    ["gif", "img"],
    ["mp4", "video"],
    ["3gp", "video"],
    // Add more file extensions and types as needed
  ]);
  const extension = url?.split(".").pop().split("?")[0];
  return types.get(extension);
}
