import styles from "./searchHeader.module.css";

const SearchHeader = () => {
  return (
    <>
      <div className={styles.search_header}>
        <div className={styles.head}>
          Chat with our AI and <br /> Get the Coolest{" "}
          <span>Personalized Gift</span> Ideas in Seconds
        </div>

        <div className={styles.subhead}>
          What’s the occasion? Who’s the gift for? Type anything you want about
          the gift you’re looking for and let our AI take care of the rest!
        </div>
      </div>
    </>
  );
};

export default SearchHeader;
